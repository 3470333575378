
import React, { lazy, Suspense } from "react";

const DoodleItem = lazy(() => import("../components/Doodle"));

function Doodles() {

    return(
        <div>
            <Suspense fallback={<div>Loading...</div>}>
                <DoodleItem />
            </Suspense>
        </div>
    )

}

export default Doodles;