import "../App.scss";
import { NavLink } from "react-router-dom";
import { ReactComponent as BlueSky } from "../Icons/Bluesky_Logo.svg";
const TammyConfused = require("../assets/profilepics/tammy.png");

function NavBar() {
  // <CarcasoPortfolioBox imageName="test.jpg"></CarcasoPortfolioBox>
  // <CarcasoPortfolioBox imageName = {"./assets/tammy.png"} />
  return (
    <div className="NavBar">
       <img
          src={TammyConfused}
          alt="HomeNavButtonImage"
          className="HomeButtonImage"
        />
      <NavLink
        to="/"
        className={({ isActive, isPending }) =>
          `homeNav ${isPending ? "pending" : isActive ? "active" : ""}`
        }
      >
        <p>Profile Pictures</p>
      </NavLink>
        <NavLink
          to="/gallery"
          className={({ isActive, isPending }) =>
        isPending ? "pending" : isActive ? "active" : ""
          }
        >
          <p>Gallery</p>
        </NavLink>
        <NavLink
          to="/doodles"
          className={({ isActive, isPending }) =>
        isPending ? "pending" : isActive ? "active" : ""
          }
        >
          <p>Doodles</p>
        </NavLink>
      <BlueSky
        onClick={() =>
          window.open("https://bsky.app/profile/tamingthecarcasoan.bsky.social", "_blank")
        }
        className="NavTwitterSocials"
        style={{ width: "40px", height: "90px" }} viewBox="0 0 610 1340" preserveAspectRatio="none"
      />
    </div>
  );
}

export default NavBar;
